export default (livewireData, list = [], defaults = [], disabled = false) => ({
    open: false,
    values: [],
    defaultValues: [],
    list: list,
    livewireData: livewireData,
    disabled: disabled,

    /**
     * Initialize the component
     */
    init() {
        if (0 !== defaults.length) {
            this.values = this.cloneArrayOrProxyArray(defaults);
            this.defaultValues = this.cloneArrayOrProxyArray(defaults);
        }
        this.$watch('values', (values) => {
            this.publish(values);
        });
    },

    /**
     * Detect if should add or remove a value
     * @param {String|Number} val The value to add in the select bag
     */
    addOrRemoveValue(val) {
        if (!this.values.includes(val)) {
            this.addValue(val);
        } else {
            this.removeValue(val);
        }
    },

    /**
     * Add a value in the select bag
     * @param {String|Number} val The value to add in the select bag
     */
    addValue(val) {
        if (!this.values.includes(val)) {
            this.values.push(val);
        }
    },

    /**
     * Remove a value from the select bag
     * @param {String|Number} val The value to remove from the select bag
     */
    removeValue(val) {
        if (this.values.includes(val)) {
            const index = this.values.indexOf(val);
            this.values.splice(index, 1);
        }
    },

    /**
     * Checks if a value is already selected
     * @param {String|Number} val The value to check
     */
    isSelected(val) {
        return this.values.includes(val);
    },

    /**
     * @param {any} values Values to publish to Livewire
     */
    publish(values) {
        if (!this.disabled) {
            this.$wire.set(this.livewireData, values);
        }
    },

    /**
     * Reset the values bag.
     */
    reset() {
        this.values = this.cloneArrayOrProxyArray(this.defaultValues);
    },

    /**
     * Clone an array or a proxy array.
     * @param {Proxy|Array} needle
     */
    cloneArrayOrProxyArray(needle) {
        return structuredClone(Object.assign([], needle));
    },
});
