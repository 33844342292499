import PurchaseOrderDoesntExists from "../../exceptions/PurchaseOrderDoesntExists";

/**
 * @typedef {{hasAllNeeded: Boolean, isPurchaseOrderExists: Boolean}} CheckResponse
 */
export default () => ({
    /** @var {Boolean} displaySubmittingBlock x-show property*/
    displaySubmittingBlock: false,

    /** @var {Boolean} isPurchaseOrderCreated x-show property */
    isPurchaseOrderCreated: false,

    /** @var {Boolean} isPurchaseOrderAlreadyCreated x-show property */
    isPurchaseOrderAlreadyCreated: false,

    /** @var {Boolean} isPurchaseOrderUpdated x-show property */
    isPurchaseOrderUpdated: false,

    /** @var {Boolean} isPurchaseOrderAlreadyUpdated x-show property */
    isPurchaseOrderAlreadyUpdated: false,

    /** @var {Boolean} isPurchaseOrderExists response state */
    isPurchaseOrderExists: false,

    /** @var {Boolean} pendingSubmitPurchaseOrder response state */
    pendingSubmitPurchaseOrder: false,

    /** @var {Boolean} isUpdating asynchronous call state */
    isUpdating: false,

    /** @var {String} errorMessage */
    errorMessage: "",

    /** @var {Boolean} hasAllNeeded response state */
    hasAllNeeded: false,

    count: 0,

    /**
     * The user would like to create/update a purchase order
     *
     * @param {Number|null} purchaseOrderId
     */
    wouldLikeToSubmitPo(purchaseOrderId = null) {
        if (this.isUpdating) {

            return;
        }

        // Reset the error message
        this.errorMessage = '';
        this.isPurchaseOrderAlreadyCreated = false;
        this.isPurchaseOrderAlreadyUpdated = false;

        // A click display/Hide the submitting part
        this.displaySubmittingBlock = true;

        // We only perform request when the block is displayed
        if (!this.displaySubmittingBlock) {

            return;
        }

        // The purchase order should be to create...
        if (null === purchaseOrderId) {
            // Prevent multiple PO creations
            if (this.isPurchaseOrderCreated) {
                this.isPurchaseOrderAlreadyCreated = true;

                return;
            }

            // Set display as pending
            this.isUpdating = true;

            this.$wire.call('canCreateOrUpdatePurchaseOrder')
                .then((data) => this.checkCanCreatePurchaseOrder(data, this.$wire))
                .catch((e) => this.displayError(e, this.$wire));

            return;
        }
        // Or to update.

        // Prevent multiple PO creations
        if (this.isPurchaseOrderUpdated) {
            this.isPurchaseOrderAlreadyUpdated = true;

            return;
        }

        // Set display as pending
        this.isUpdating = true;

        this.$wire.call('canCreateOrUpdatePurchaseOrder')
            .then((data) => this.checkCanUpdatePurchaseOrder(data, this.$wire))
            .catch((e) => this.displayError(e, this.$wire));
    },

    /**
     * Check if you can create a purchase order.
     *
     * @param {CheckResponse} response
     */
    checkCanCreatePurchaseOrder(response, wire) {
        // Update display to show everything is okay
        this.hasAllNeeded = response.hasAllNeeded ? true : false;
        this.isPurchaseOrderExists = response.isPurchaseOrderExists ? true : false;

        // Update display now
        if (!this.hasAllNeeded) {
            this.isUpdating = false;

            return;
        }

        // Prevent multiple PO creation (from response content)
        if (this.isPurchaseOrderExists) {
            this.isPurchaseOrderAlreadyCreated = true;

            return ;
        }

        // We can perform the creation only if we have everything required
        this.callCreatePurchaseOrder(wire);
    },

    /**
     * Ask backend to create the purchase order.
     */
    callCreatePurchaseOrder(wire) {
        this.isUpdating = true;


        wire.call('createPurchaseOrder')
            .then((response) => {
                this.isUpdating = false;

                // Can either reset or set a new message
                this.errorMessage = response.errorMessage;

                if (response.done) {
                    this.isPurchaseOrderCreated = true;
                    wire.emit('refreshPurchaseOrder');
                }
            })
            .catch((e) => this.displayError(e, wire));
    },

    /**
     * Check if you can create a purchase order.
     *
     * @param {CheckResponse} response
     */
    checkCanUpdatePurchaseOrder(response, wire) {
        // Update display to show everything is okay
        this.hasAllNeeded = response.hasAllNeeded ? true : false;
        this.isPurchaseOrderExists = response.isPurchaseOrderExists ? true : false;

        // Update display now
        if (!this.hasAllNeeded) {
            this.isUpdating = false;

            return;
        }

        // Prevent multiple PO update (from response content)
        if (!this.isPurchaseOrderExists) {
            this.displayError(
                new PurchaseOrderDoesntExists("The purchase order was supposed to be updated, but no purchase order was actually referenced."),
                wire,
            );

            return;
        }

        // We can perform the update only if we have everything required
        this.callUpdatePurchaseOrder(wire);
    },

    /**
     * Ask backend to update the purchase order.
     */
    callUpdatePurchaseOrder(wire) {

        wire.call('updatePurchaseOrder')
            .then((submitRes) => {
                this.isUpdating = false;

                // Can either reset or set a new message
                this.errorMessage = submitRes.errorMessage;

                if (submitRes.done) {
                    this.isPurchaseOrderUpdated = true;
                }
            })
            .catch((e) => this.displayError(e, wire));
    },

    /**
     * Display an error to the user
     *
     * @param {Error} error
     */
    displayError(error, wire) {
        if (undefined === error || null === error || !(error instanceof Error)) {
            error = new Error("Unknown error");
        }

        wire.call('addLogAboutPurchaseOrder', error.name, error.message);

        alert('Something goes wrong. Reload the page and if it continues, contact an administrator');

        throw error;
    },
});
