export default class Exception extends Error {
    /**
     * Create a new instance of Exception
     * @param {String} message
     */
    constructor(message) {
        super(message);
        this.name = this.constructor.name;
    }
}
